import _axios from 'axios'
import log from './logger'

var baseURL = '/api'

if (window.location.hostname.match(/localhost|10.0.0.40/)) {
	baseURL = 'http://10.0.0.40:5000/api'
}

const axios = {
	get: async function (url, params, opt = {}) {
		try {
			return _axios.get(baseURL + url, { ...opt, params })
		}
		catch (e) {
			log.error(e)
		}
		return null
	},
	post: async function (url, data = {}, opt = {}) {
		try {
			return _axios.post(baseURL + url, data, opt)
		} catch (e) { log.error(e) }
		return null
	}
}
export default axios